import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import Layout from 'components/LandingLayout';
import metaTranslations from '../../lib/utils';
import theme from '../../themes';
import SEO from 'components/seo';
import NavbarComponent from '../../components/Landing/Navbar/Navbar';
import NavbarFeature from '../../components/Landing/NavbarFeature/NavbarFeature';
import { windowGlobal } from '../../config';
import FeatureHero from '../../components/Landing/FeatureHero/FeatureHero';
import FeatureListContainer from "../../components/Landing/FeatureListContainer/FeatureListContainer";
// import KeepResources from '../../images/landing/resources_1.svg';
// import SaveResources from '../../images/landing/resources_3.svg';
// import UploadResources from '../../images/landing/resources_4.svg';
// import GetResources from '../../images/landing/resources_5.svg';
// import AccessResources from '../../images/landing/resources_6.svg';

import ResourcesHero from '../../images/landing/resources_hero_dsktp.png';
import ResourcesHeroMobile from '../../images/landing/resources_hero_mobile.png';
import { WithTrans } from '../../language/withTrans';

const FOR_TEACHERS_ARRAY = [
  {
    title: "Keep files",
    description: "Keep what you need",
    image: '',
  },
  {
    title: "Save to the cloud",
    description: "Save to the cloud",
    image: '',
  },
  {
    title: "Upload resources",
    description: "Upload valuable resources for your teachers to keep on learning",
    image: ''
  }
];

const FOR_PARENTS_ARRAY = [
  {
    title: "Get documents",
    description: "Get documents directly from teachers (videos, PDF’s, images)",
    image: '',
  },
  {
    title: "virtual",
    description: "Access your resources on the cloud",
    image: '',
  },
];

const FEATURE_VIEW = 'ResourcesView';
const COLOR_MESSAGES = "rgb(249,186,72,0.1)";

const Resources = ({
  lang,
}) => {
  windowGlobal.localStorage.setItem('LANDING_LOCALE', lang);

  return (
    <Layout>
      <SEO
        title={metaTranslations[lang].title}
        description={metaTranslations[lang].description}
        meta={[{ name: 'og:image', content: 'https://kinedu-media.s3.amazonaws.com/classrooms_by_kinedu_2.jpg' }]}
      />
      <ThemeProvider theme={theme}>
        <NavbarComponent lang={'es'} />
        <NavbarFeature view={FEATURE_VIEW} />
        <div>
          <FeatureHero
            title={'Upload and share your own lesson plans'}
            imageDesktop={ResourcesHero}
            imageMobile={ResourcesHeroMobile}
          />
        </div>

        <FeatureListContainer
          color={COLOR_MESSAGES}
          itemsForTeachers={FOR_TEACHERS_ARRAY}
          itemsForParents={FOR_PARENTS_ARRAY}
        />
      </ThemeProvider>
    </Layout>
  );
};

export default WithTrans()(Resources);
